import { AuthAuthority, UserAuthInfo } from "@use-cases/GetUserInfoInteractor";
import fetchJsonp from "fetch-jsonp";

interface WhoAmIResponse {
  signedIn: boolean;
  accountUID: string;
  email: string;
  supportSLA: string;
  name: string;
}
export class NotehubAuth implements AuthAuthority {
  private notehubBaseURL: URL;

  constructor({ notehubBaseURL }: { notehubBaseURL: URL }) {
    this.notehubBaseURL = notehubBaseURL;
  }

  notehubWhoAmI(): URL {
    // e.g. https://notehub.io/whoami.js
    return new URL("whoami.js", this.notehubBaseURL);
  }

  userInfo(
    accountUID: string,
    email: string,
    name: string,
    signedIn: boolean,
    supportSLA: string
  ): UserAuthInfo {
    return {
      accountUID,
      email,
      name,
      signedIn,
      supportSLA,
    };
  }

  authEndpoints() {
    return {
      signInURL: (returnURL: URL): URL => {
        // e.g. https://notehub.io/sign-in?return_to=https://dev.blues.io/here
        const u = new URL(`sign-in`, this.notehubBaseURL);
        u.searchParams.append("return_to", `${returnURL}`);
        return u;
      },
      signOutURL: (returnURL: URL): URL => {
        // e.g. https://notehub.io/sign-out?return_to=https://dev.blues.io/there
        const u = new URL(`sign-out`, this.notehubBaseURL);
        u.searchParams.append("return_to", `${returnURL}`);
        return u;
      },
      signUpURL: (returnURL: URL): URL => {
        // e.g. https://notehub.io/sign-up?return_to=https://dev.blues.io/overthere
        const u = new URL(`sign-up`, this.notehubBaseURL);
        u.searchParams.append("return_to", `${returnURL}`);
        return u;
      },
      viewProjectsURL: (_returnURL: URL): URL =>
        // e.g. https://notehub.io/projects?return_to=https://dev.blues.io/overthere
        new URL("projects", this.notehubBaseURL),
      accountSettingsURL: (_returnURL: URL): URL =>
        // e.g. https://notehub.io/account-settings
        new URL("account-settings", this.notehubBaseURL),
    };
  }

  async getUserInfo() {
    return new Promise<UserAuthInfo>((resolve, reject) => {
      fetchJsonp(this.notehubWhoAmI().toString())
        .then((response) =>
          // console.log("response:" + JSON.stringify(response));
          response.json()
        )
        .then((json: WhoAmIResponse) => {
          resolve(
            this.userInfo(
              json.accountUID,
              json.email,
              json.name,
              json.signedIn,
              json.supportSLA
            )
          );
        })
        .catch((e) => {
          reject(new Error(`NotehubAuth fetchJsonp error: ${e}`));
        });
    });
  }
}
